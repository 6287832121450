import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useClientUser } from 'contexts/ClientUserProvider'
import { RegisterProvider } from 'contexts/RegisterProvider'
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Personal = React.lazy(() => import('./views/pages/register/Personal'))
const Company = React.lazy(() => import('./views/pages/register/Company'))
const Store = React.lazy(() => import('./views/pages/register/Store'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () =>  {
  const {isLoggedIn} = useClientUser()

    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
          {isLoggedIn 
          ? 
          <Switch>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
          </Switch>
          :
          <RegisterProvider >
            <Switch>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/personal" name="Personal Page" render={props => <Personal {...props}/>} />
              <Route exact path="/store" name="Store Page" render={props => <Store {...props}/>} />
              <Route path="/" name="login" render={props => <Login {...props}/>} />
            </Switch>
          </RegisterProvider>
        }   

        </React.Suspense>
      </BrowserRouter>
    );
  
}

export default App;