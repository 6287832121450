import { gql } from '@apollo/client';

const OPTION_GROUP_ATTRIBUTES = gql`
  fragment optionGroupAttributes on OptionGroup {
    _id
    store_id
    group_name
    description
    max_select
    min_select
    sort
    options
  }
`

export const GET_OPTION_GROUP = gql`
  query($store_id: ID){
    optionGroups(store_id:$store_id){
      totalResult
      result {
        ...optionGroupAttributes
      }
      
    }
  }${OPTION_GROUP_ATTRIBUTES}
`;
