import React, {useContext, useState, createContext, useEffect} from 'react';
import _isNil from "lodash-es/isNil"
import { useQuery, useApolloClient } from '@apollo/client';

import {SIGN_IN, GET_CURRENT_USER, CHANGE_PASS} from 'query'


const ClientUserContext = createContext(null);

const ClientUserProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const [ mainLoading, setMainLoading ] = useState(true)
  const [ isLoggedIn, setIsLoggedIn ] = useState(false)
  const [ clientUserData, setClientUserData ] = useState({})
  const [ errMsg, setErrMsg ] = useState('')


  const { loading } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: "no-catch",
    onError(err){
      setErrMsg(err)
      setMainLoading(false)
    },
    onCompleted(userData) {

      if(!_isNil(userData.currentUser)){
        setClientUserData(userData.currentUser)
        setIsLoggedIn(true)
        setMainLoading(false)
      } 
    }
  });

  const signIn = async(username, password) => {
    try{
      let result = await apolloClient.mutate({
        mutation: SIGN_IN,
        variables: {email:username, password:password},
      })
      await localStorage.setItem('@authToken', result.data.signIn.token)
      delete result.data.signIn.token
      await setClientUserData(result.data.signIn)
      await setIsLoggedIn(true)
      await setMainLoading(false)
    } catch (err){
      console.log(err)
      alert(err)
      await setMainLoading(false)
      throw err
    }
  }

  const signOut = async() => {
    await localStorage.removeItem("@authToken");
    await setClientUserData(null)
    await setIsLoggedIn(false)
    return 
  }

  const changePassword = async(password) => {
    try{
      await apolloClient.mutate({
        mutation: CHANGE_PASS,
        variables: {password:password},
      })
      return
    } catch (err){
      console.log(err)
      await setMainLoading(false)
      throw err
    }
  }

  return (
    <ClientUserContext.Provider
      value={{
        setMainLoading,
        mainLoading,
        isLoggedIn,
        clientUserData,
        setClientUserData,
        changePassword,
        signIn,
        signOut,
        signIn,
      }}>
      {children}
    </ClientUserContext.Provider>
  );
};

const useClientUser = () => {
  const clientUser = useContext(ClientUserContext);
  if (clientUser == null) {
    throw new Error('clientUser() called outside of a AuthProvider?');
  }
  return clientUser;
};

export {ClientUserProvider, useClientUser};


