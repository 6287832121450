import { ApolloClient, InMemoryCache, from, HttpLink, split} from '@apollo/client';
import { RetryLink } from "@apollo/client/link/retry";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from "subscriptions-transport-ws";
import { onError } from "@apollo/client/link/error";
import Config from './config'

const GRAPHQL_HTTP_ENDPOINT = Config().GRAPHQL_HTTP;
const GRAPHQL_WS_ENDPOINT = Config().GRAPHQL_WS;

const wsClient = new SubscriptionClient(GRAPHQL_WS_ENDPOINT, {
  lazy: true,
  reconnect: true,
  connectionParams: async () => {
    const token = await localStorage.getItem('@authToken')
    return {
      authorization: token ? token : null
    }
  }
})

const wsLink = new WebSocketLink(wsClient)

// Http Link
const httpLink = new HttpLink({
  uri: GRAPHQL_HTTP_ENDPOINT,
  credentials: 'include',
})

// splitLink WS and HTTP
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// Retury 
const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    const isMutation =
      operation &&
      operation.query &&
      operation.query.definitions &&
      Array.isArray(operation.query.definitions) &&
      operation.query.definitions.some(
        (def) =>
          def.kind === 'OperationDefinition' && def.operation === 'mutation'
      )
    if (isMutation) {
      return !!error && count < 25
    }
    return !!error && count < 6
  }
})

// Error Link 
const errorLink = onError((err) => {
  if (err.graphQLErrors)
    err.graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error on lib/apollo.js ]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
        // alert(message)
    })
  if (err.networkError) {
    console.log(`[Network error]`, err.networkError)
    // alert('Network Error',` ${err.networkError}`)
  }
  console.log(err)
})

const authLink = setContext(async (_, { headers }) => {
  const token = await localStorage.getItem('@authToken')
  return {
    headers: {
      ...headers,
      authorization: token ? token : null
    }
  }
})

// Apollo Server 
export const apolloClient = new ApolloClient({
  cache:new InMemoryCache(),
  link: from([
    errorLink, 
    retryLink, 
    authLink,
    splitLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'no-catch',
      errorPolicy: 'all'
    },
  }
});