import React, {useContext, useState, createContext, useEffect} from 'react'
import _isNil from "lodash-es/isNil"
import _isEmpty from 'lodash-es/isEmpty'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {GET_CATEGORIES} from 'query'

const CategoryContext = createContext(null);

const CategoryProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {selectedStoreID} = useStore()
  const [categoryLists, setCategoryLists] = useState([])

  useEffect (() => {
    if(selectedStoreID == null || selectedStoreID !== ''){
      getCategoryList(selectedStoreID)
    }
  },[selectedStoreID])

  const getCategoryList = async(store_id) => {
    try{
      if(store_id == null || store_id ==''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        query: GET_CATEGORIES,
        variables: {store_id:store_id},
      })
      console.log(result.data.categories)
      setCategoryLists(result.data.categories)
    } catch (err){
      throw err
    }
  
  }

  return (
    <CategoryContext.Provider
      value={{
        categoryLists
      }}>
      {children}
    </CategoryContext.Provider>
  );
};

const useCategory = () => {
  const contx = useContext(CategoryContext);
  if (contx == null) {
    throw new Error('useCategory() called outside of a CategoryProvider?');
  }
  return contx;
};

export {CategoryProvider, useCategory};


