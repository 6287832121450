import React, {useContext, useState, createContext, useEffect} from 'react'
import _isNil from "lodash-es/isNil"
import _isEmpty from 'lodash-es/isEmpty'
import { useApolloClient } from '@apollo/client'
import {useStore} from 'contexts/StoreProvider'
import {useClientUser} from 'contexts/ClientUserProvider'
import {GET_OPTION_GROUP} from 'query'

const OptionGroupContext = createContext(null);

const OptionGroupProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {clientUserData} = useClientUser()
  const {selectedStoreID} = useStore()
  const [optionGroupLists, setOptionGroupLists] = useState([])

  useEffect (() => {
    if(selectedStoreID == null || selectedStoreID !== ''){
      getOptionGroupLists(selectedStoreID)
    }
  },[selectedStoreID])

  // useEffect (() => {
  //   console.log(optionGroupLists)
  // },[optionGroupLists])

  const getOptionGroupLists = async(store_id) => {
    try{
      if(store_id == null || store_id ==''){
        throw 'store_id cannot be empry or null'
      }
      let result = await apolloClient.query({
        query: GET_OPTION_GROUP,
        variables: {store_id:store_id},
      })
      setOptionGroupLists(result.data.optionGroups)
    } catch (err){
      throw err
    }
  
  }

  return (
    <OptionGroupContext.Provider
      value={{
        optionGroupLists
      }}>
      {children}
    </OptionGroupContext.Provider>
  );
};

const useOptionGroups = () => {
  const contx = useContext(OptionGroupContext);
  if (contx == null) {
    throw new Error('useOptionGroups() called outside of a OptionGroupProvider?');
  }
  return contx;
};

export {OptionGroupProvider, useOptionGroups};


