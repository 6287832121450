import { gql } from '@apollo/client';

const QR_CODE_ATTRIBUTES = gql`
  fragment qrCodeAttributes on QrCode {
    _id
    qr_code
    name
    company_id
    store_id
    store_name 
    timer
    type
    payment_types
  }
`

export const GET_QR_CODE = gql`
  query($store_id: ID){
    qrCode(store_id:$store_id){
      totalResult
      result {
        ...qrCodeAttributes
      }
      
    }
  }${QR_CODE_ATTRIBUTES}
`;
