const { REACT_APP_APP_VERSION, REACT_APP_ENV_TYPE } = process.env;

const admin = {
    APP_VERSION: REACT_APP_APP_VERSION || "v0.0.0",

    // GRAPHQL_HTTP: "http://localhost:5002/graphql",
    // GRAPHQL_WS: "ws://localhost:5002/wsql",

    GRAPHQL_HTTP: "https://pos-server02.bereach.co/graphql",
    GRAPHQL_WS: "wss://pos-server02.bereach.co/wsql",
}

// SUPER ADMIN
const superadmin = {
    APP_VERSION: `${REACT_APP_APP_VERSION || "v0.0.0"} -- SuperAdmin`,

    // GRAPHQL_HTTP: "http://localhost:5005/graphql",
    // GRAPHQL_WS: "ws://localhost:5005/wsql",

    GRAPHQL_HTTP: "https://superadmin-server.bereach.co/graphql",
    GRAPHQL_WS: "wss://superadmin-server.bereach.co/wsql",
}

export default () => {
    if(REACT_APP_ENV_TYPE == "superadmin"){
        return superadmin
    }
    return admin
}
