import { gql } from '@apollo/client';

const STORES_ATTRIBUTES = gql`
  fragment storeAttributes on Store {
    _id
    display_name
    store_name
    store_comapny_reg_no
    company_id
    email
    tel
    website_url
    description
    logo_image
    images
    status
    currency_id
    name
    order_status
    store_company_reg_no
    last_bill_number
    isOpen
    operationType
    whatsapp   
    xp_printer_sn
  }
`

export const WATCH_STORE = gql`
  subscription ($_id: ID){
    watchStore(_id: $_id){
      _id
      isOpen
    }
  }
`;

export const GET_STORE = gql`
  query($store_id: ID){
    store(store_id:$store_id){
      ...storeAttributes
    }
  }${STORES_ATTRIBUTES}
`;

export const UPDATE_STORE_ISOPEN = gql`
  mutation ($_id: ID, $isOpen:Boolean){
    updateStoreIsOpen(_id:$_id, isOpen:$isOpen){
      ...storeAttributes
    }
  }${STORES_ATTRIBUTES}
`;