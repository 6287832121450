import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import App from './App';
import { ClientUserProvider } from './contexts/ClientUserProvider'
import { StoreProvider } from './contexts/StoreProvider'
import {CategoryProvider} from 'contexts/CategoryProvider'
import {OptionGroupProvider} from 'contexts/OptionGroupProvider'
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './lib/apollo'
// import * as serviceWorker from './serviceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import store from './store'

import { icons } from './assets/icons'

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <ClientUserProvider>
        <StoreProvider>
          <CategoryProvider>
            <OptionGroupProvider>
              <App/>
            </OptionGroupProvider>
          </CategoryProvider>
        </StoreProvider>
      </ClientUserProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
// serviceWorkerRegistration.register();
serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
