import React, {useContext, useState, createContext, useEffect} from 'react'
import _isNil from "lodash-es/isNil"
import _isEmpty from 'lodash-es/isEmpty'
import { useApolloClient } from '@apollo/client'
import {useClientUser} from 'contexts/ClientUserProvider'
import {GET_STORE} from 'query'

const StoreContext = createContext(null);

const StoreProvider = ({children}) => {
  const apolloClient = useApolloClient();
  const {clientUserData} = useClientUser()
  const [selectedStoreID, setSelectedStoreID] = useState(null)
  const [selectedStore, setSelectedStore] = useState([])
  const [storeIsLoading, setStoreIsLoading ] = useState(true);

  const ProccessFlowType = [
    {label:"Pending", value:"pending", next:null},
    {label:"Processing", value:"processing", next:null},
    {label:"Confirmed", value:"confirmed", next:null},
    {label:"Completed", value:"completed", next:null},
  ]

  useEffect (() => {
    if(selectedStoreID !== null) return
    if(!_isEmpty(clientUserData)){
      setCurrentStore(clientUserData.stores[0]._id)
    }
  },[clientUserData])



const setCurrentStore = async (store_id) => {
  try{
    setStoreIsLoading(true)
    await getCurrentStore(store_id)
    setStoreIsLoading(false)
  } catch (err){
    console.log(err)
    throw err
  }
}

const getCurrentStore = async(store_id) => {
  try{
    if(store_id == null || store_id ==''){
      throw 'store_id cannot be empry or null'
    }
    let result = await apolloClient.query({
      query: GET_STORE,
      variables: {store_id:store_id},
    })
    setSelectedStoreID(store_id)
    setSelectedStore(result)
  } catch (err){
    throw err
  }
}

  return (
    <StoreContext.Provider
      value={{
        setCurrentStore,
        selectedStore,
        selectedStoreID,
        storeIsLoading,
        ProccessFlowType
      }}>
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => {
  const store = useContext(StoreContext);
  if (store == null) {
    throw new Error('useStore() called outside of a StoreProvider?');
  }
  return store;
};

export {StoreProvider, useStore};


