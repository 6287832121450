import { gql } from '@apollo/client';

const PRODUCTS_ATTRIBUTES = gql`
  fragment productAttributes on Product {
    _id
    name
    brand
    description
    price
    categories
    options
    status
    sort
    tags
    main_image
    images,
    created_date
    updated_date
    store_id
  }
`

export const GET_PRODUCTS = gql`
  query($store_id: ID){
    products(store_id:$store_id){
      totalResult
      result {
        ...productAttributes
      }
      
    }
  }${PRODUCTS_ATTRIBUTES}
`;

export const CHANGE_PRODUCT_STATUS = gql`
  mutation ($_id: ID, $status:Int){
    changeProductStatus(_id:$_id, status:$status)
  }
`;
