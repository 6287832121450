import { gql } from '@apollo/client';

const COMPANY_ATTRIBUTES = gql`
  fragment companyAttributes on Company {
    _id
    display_name
    address
    phone
    email
    description
    gps_location
    main_image
  }
`
export const GET_COMPANY = gql`
  query{
    company{
      ...companyAttributes
    }
  }${COMPANY_ATTRIBUTES}
`;
