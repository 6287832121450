import { gql } from '@apollo/client';

const CATEGORIES_ATTRIBUTES = gql`
  fragment categorytAttributes on Category {
    _id
    name
    display_name
    sort
    image
    store_id
    status
  }
`

export const GET_CATEGORIES = gql`
  query($store_id: ID){
    categories(store_id:$store_id){
      totalResult
      result {
        ...categorytAttributes
      }
      
    }
  }${CATEGORIES_ATTRIBUTES}
`;
